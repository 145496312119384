/*
 * GLOBAL LAYOUT
 */

header, footer {
	display: table;
    padding: 0 2vw;
    height: $header-height;
    width: 100vw;
}

header nav, footer nav {
    float: right;
}

header nav ul, footer nav ul {
    margin: 0; padding: 0;
    list-style-type: none;
}


/*
 * HEADER
 */

header {
    font: $header-font;
    background-color: white;
    box-shadow: rgba(69, 91, 99, 0.15) 0px 4px 16px 0px;
	z-index: 10;
}

header > * {
    display: table-cell;
    height: $header-height;
    vertical-align: middle;
}

header input, header label {
	display: none;
}

header nav ul {
    display: inline-block;
}

header nav ul li, 
header nav div {
    display: inline-block;
    margin:0;
    padding: 0 1vw 0 0;
    line-height: $header-height;
}

header nav ul li a, 
header nav div a {
    text-transform: uppercase;
    opacity: 1;
    transition: opacity .4s ease-out;
    font-weight: 400;
    font-size: 16px;
}

header nav ul li a:hover,
header nav div a:hover {
    opacity: .8;
}

header nav ul li a.active,
header nav div a.active  {
    color: $blue;
}

/*
 * FOOTER
 */

footer {
    height: $header-height;
    color: white;
    background-color: $grey;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 0 4px 16px 0 rgba(0,0,0,0.75);
    -webkit-box-shadow: 0 4px 16px 0 rgba(0,0,0,0.75);
}

footer nav ul li {
    display: inline-block;
    padding: 0 1vw 0 0;
    font: $footer-font;
    line-height: $header-height;
}


/*
 * SECTION
 */

section {
    min-height: 17vw;
}

section:nth-of-type(odd) {
    color: white;
    background-color: $grey;
    border-color: transparent $grey;
}

section:nth-of-type(even) {
    color: $grey;
    background-color: white;
    border-color: transparent white;
}

section::before {
    display: block;
    position: absolute;
    height: 0; width: 0;
    left: 0;
    content: '';
    border-style: solid;
    border-color: inherit;
}

section::before {
    top: calc(-1 * #{$skew-height});
}
    