.row {
    display:flex;
    margin-bottom: 2rem;
}
.column {
    flex:50%;
    &-2 {
        flex:66%;
    }
    &-3 {
        flex:33%;
    }
}


@media (max-width: 767px) {
    .row:not(.share-icons) {
        flex-direction: column;
    }
    .d-xs-none {
        display: none;
    }
}
@media (min-width: 768px) {
    .column:not(:last-child), 
    .column-2:not(:last-child),
    .column-3:not(:last-child) {
        margin-right: 2rem;
    }
}

/*
 * SPECIFIC LAYOUT
 * INDEX
 */

#index {
    background-color: black;
}

#index section {
    text-align: center;
}

#index section > * {
    top: $jump-back;
}

#index section:nth-of-type(odd) {
    margin: 10vw 0;
    padding: 0 41vw 0 7vw;
    border-top-width: 1px;
    border-top-style: solid;
}

#index section:nth-of-type(even) {
    padding: 0 7vw 0 41vw;
    border-top-width: 1px;
    border-top-style: solid;
}

#index section:last-child {
    margin: 10vw 0 0;
}

#index section:nth-of-type(odd)::before {
    border-width: $top-left
}

#index section:nth-of-type(odd)::after {
    border-width: $bottom-right
}

#index section:nth-of-type(even)::before {
    border-width: $top-right
}

#index section:nth-of-type(even)::after {
    border-width: $bottom-left
}

#index section:first-of-type {
    min-height: 90vh;
    margin-top: 0;
    padding: 8vw 18vw 5vw 18vw;
    text-align: left;
}

#index section:first-of-type > * {
    top: 0;
}

#index section:first-of-type > p {
    width: 80%;
}

/*
 * SPECIFIC LAYOUT
 * CASE
 */

#case section:nth-of-type(n)::before {
    display: none;
}

#case section:nth-of-type(1) {
    padding: 10vw 11vw 0;
    text-align: right;
}

#case section:nth-of-type(1)::after {
    border-width: $bottom-right
}

#case section:nth-of-type(2) {
    padding: calc(.75 * #{$skew-height}) 30vw 5vw 10vw;
    text-align: left;
    background-color: transparent;
}

#case section:nth-of-type(2)::after {
    top: unset; bottom: 0;
    border-color: $grey transparent;
    border-width: $bottom-right;
}

#case section:nth-of-type(2) > p:last-child {
    padding-bottom: calc(#{$skew-height} - 5vw)
}

#case section:nth-of-type(3) {
    padding: 0 40vw 0 10vw;
    text-align: left;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

#case section:nth-of-type(3)::after {
    border-width: 0 0 calc(1.5 * #{$skew-height}) 100vw;
    z-index: 1;
}

#case section:nth-of-type(4) {
    padding: 20vw 10vw 5vw 35vw;
    text-align: right;
    border-top-width: 1px;
    border-top-style: solid;
}

#case section:nth-of-type(n+5) {
    display: none;
}

/*
 * ABOUT LAYOUT
 */

#about {
    background-color: black;
}

#about section:first-of-type {
	height: 70vw; min-height: 60vh;
	padding: 10vw 10vw 0;
	background: url('../img/about-header.png') center center no-repeat;
	background-size: cover;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 0 90%);
	clip-path: polygon(0 0, 100% 0, 100% 60%, 0 90%);
    z-index: 1;
}

#about section:first-of-type h1 {
	font: var(--h1-about-font)
}

#about section:nth-of-type(2) {
	margin-top: calc(2 * #{$jump-back});
	padding: 10vw 10vw 0;
	text-align: center;
}

#about section:nth-of-type(2)::before {
    border-width: $top-left
}

#about section:nth-of-type(2)::after {
    top: unset; bottom: 0;
    border-color: $grey transparent;
    border-width: $bottom-right
}

#about section:nth-of-type(2) h2 {
	float: left;
	width: 35vw;
	padding: 14vw 0 3vw;
	text-transform: none;
}

#about section:nth-of-type(2) p {
	float: right;
	width: 40vw;
	padding: 4vw;
}

#about section:nth-of-type(3) {
	padding: 5vw 18vw 0;
    text-align: left;
}

#about section:nth-of-type(3)::after {
	height: 32vw; width: 100vw;
	background-color: $grey
}

#about section:nth-of-type(4) {
	margin-top: -5vw;
	height: 70vw; min-height: 60vh;
	background: url('../img/lightswitch.jpg') center center no-repeat;
	background-size: auto 100%;
	-webkit-clip-path: polygon(0 35%, 100% 0, 100% 100%, 0 65%);
    clip-path: polygon(0 35%, 100% 0, 100% 100%, 0 65%);
	z-index: 1;
}

#about section:nth-of-type(5) {
	margin-top: -10vw;
    padding: 0 35vw 20vh 10vw;
    text-align: left;
	color: $grey;
    background-color: white;
    border-top-width:1px;
    border-top-style:solid;
}

#about section:nth-of-type(5)::before {
    border-color: transparent white;
    border-width: $top-right;
}

#about section:nth-of-type(n+6) {
    display: none;
}

/* 
 * POSTS LAYOUT
 */

#posts {

    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-content: center;

    h1 {
	padding: 17vh 12vw 3vh;
	width: 100vw;
	color: white;
    }

    section {
        display: inline-block;
        margin: 10vh 0; 
        width: 620px;
        color: $grey;
        vertical-align: top;
        background-color: white;
        border: none;
        border-radius: 0 10px 10px 10px;
        box-shadow: 0 4px 16px 0 rgba(69,91,99,.25);
        -moz-box-shadow: 0 4px 16px 0 rgba(69,91,99,.25);
        -webkit-box-shadow: 0 4px 16px 0 rgba(69,91,99,.25);

        .label {
            display: flex;
            align-items: center;
            position: absolute;
            top: -4vh; left: 0;
            height: 4vh; width: auto;
            padding: 0 1vw;
            font: var(--tag-font);
            text-transform: uppercase;
            color: white;
            background-color: $grey;
            border: none;
            border-radius: 10px 10px 0 0;
        }
    }
}

@media (max-width: 1024px) {
    #posts {
        flex-flow: column;
        align-items: center;
    }
}

/*
 * CONTACT
 */

#contact {
    margin: 10vh auto 20vh;
    color: $grey;
    max-width: 90vw;

    .underline {
        text-decoration: underline;
    }

    h1 strong {
        display: block;
    }

    section {        
	    padding: 1vw 10vw;
        color: $grey;
        background-color: white;
        border-color:unset;

        &::before, &::after {
            content: none;
        }
    }    

    form {
        float:left;
        width: 40vw;
        margin:5vh;
        margin-left: 0;
        text-align: right;

        #contactCaptcha {
            width: 10vw; min-width: 200px;
        }
    }

    address {

        font: var(--tag-font);
        width:16vw;
        margin:5vh;
        margin-right: 0;
        float:left;

        ul, li {
            margin: 10px 0;
            padding: 0;
            list-style-type: none;
        }
    }
}

/*
 * BLOG
 */

main#blog {
    background-size:cover;
    background-image:url('/themes/notonpaper/assets/images/background.svg');
    padding: 18vh 15vw;
    color: $grey;
}


main#blog .container {
    background-color: white;
    padding:2rem;
    box-shadow: 0 0 12px 2px rgba(65, 65, 65, 0.25);
    border-radius: 10px;
}

@media (max-width: 767px) {    
    main#blog, .container {
        padding:1rem !important;
    }
}


main#blog > *:not(h1) {
    margin: 1vw;
}

main#blog h1 {
    margin-bottom: 6vh;
}

main#blog h4 {
    margin-top: 7vh;
    font-weight: 400;
}

main#blog ul {
    padding-bottom: 0;
    margin-bottom: 0;
}

main#blog a {
    text-decoration: underline;
}

/*
 * 404
 */

main#error {
    margin: 15vh auto;
    height: 40vw; width: 80vw;
}

main#error .error-rocket {
    display: inline-block;
    width: 30vw;
    transform: rotate(17.5deg);
}

main#error span {
    display: inline-block;
    width: 40vw;
    text-align: center;
    vertical-align: top;
}