/* 
    Maximum
*/

@media (min-width: 1680px) {
    html, body {
        font-size: 20px;
    }
}

/* 
  Tablets
*/

@media (max-width: 1023px) {
    html, body {
        font-size: 16px;
    }

    :root {
        --h1-font       : normal normal 200 4.5rem/5rem #{$font-stack};
        --h1-about-font : normal normal 200 4.5rem/5rem #{$font-stack};
        --button-font   : normal normal 700 1rem #{$font-stack};
    }

    #index section:nth-of-type(n),
    #case section:nth-of-type(n),
    #about section:nth-of-type(n),
    #contact section:nth-of-type(n),
    #blog section:nth-of-type(n)
    {
        min-height: unset;
        padding: 7vw;
    }
    
    #about section:nth-of-type(1) {
        padding: 5vw;
        height: 70vh;
    }
    
    #about section:nth-of-type(2) h2 {
        margin-top: 10vh;
    }
    
    #case section:nth-of-type(2) {
        padding-top: 25vw;
    }
    
    #case section:nth-of-type(4) {
        padding: 20vw 10vw 5vw 20vw;
    }
    
    #case ul.tags {
        text-align: center;
    }
    
    
    #contact {
        margin: calc(1.4 * #{$header-height}) 5vw;

        form, address {
            margin-left:0;
            margin-right:0;
            width: 80vw;
            text-align: left;
        }
    }
    
    #posts {
        h1 {
            padding: 2rem;
        }
        
        section {
            width: 80vw;
            
            div.btn {
                position: relative;
                bottom:30%;
                margin-top: 1rem;
            }
            span {
                position: relative;
            }
            p {
                padding-top: 5vw;
                width: 60vw;
            }
            &::after {
            padding-bottom: 0;
            }
        }
    }
    
    main#error {
        margin: 15vh auto;
        height: auto; width: 80vw;
    }
    
    main#error .error-rocket {
        display: block;
        height: 20vh; width: 80vw;
        transform: rotate(17.5deg);
    }
    
    main#error span {
        display: block;
        width: 80vw;
        text-align: center;
    }
    
    main {
        margin-top: $header-height;
    }
    
    header {
		position: fixed;
		top: 0; left: 0;
		text-align: right;
	}

	header label {
		display: block;
		float: right;
		padding: 22px;
		cursor: pointer;
		z-index: 20;
	}

	header input[type='checkbox'] {
		display: none;
	}

	header nav {
		position: absolute;
		top: -100vh; left: 0;
		height: 100vh; width: 100vw;
		opacity: 0;
        background-color: $grey;
        transition: top .5s ease-in-out, opacity .4s ease-in-out .3s, height 0s ease 1.1s;
        
	}

	header input[type='checkbox']:checked ~ nav {
		top: 0;
		height: 200vh;
		opacity: 1;
        transition: top .5s ease-in-out .2s, height 0s ease-in-out, opacity .6s ease-in-out;
        
        &::before {
            animation: dashing-white .6s ease-out 1.5s 1 forwards;
        }
        &::after {
            animation: dashing .6s ease-in 1s 1 forwards;
        }
        .language {
            opacity: 1;
        }
	}

	header nav ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
		padding-top: 10vh;
		height: 100vh;
        background: $blue;
        background: linear-gradient(17deg, rgba(2,0,36,0) 46%, $blue 46%);
    }

    header nav ul li,
    header nav div {
		font: var(--h1-font);
		font-weight: 700;
		color: white;
        text-align: center;
        padding-top: 6vh;
        z-index: 1;
	}

	header nav ul li a, header nav ul li a:hover {
		opacity: 1;
	}

	header nav .language {
		color: white;
        font: $p-font;
        text-align: left;
        margin-left: 3rem;
        padding: 0;
        position: fixed;
        left:0;
        bottom:10.5vh;
        opacity: 0;
        transition: opacity .3s ease-out .6s;

        a {
            font-size:1.75rem; 
        }
	}

	header nav .language::before {
		content: attr(data-language);
		text-transform: uppercase;
		white-space: pre-wrap;
	}

	header nav .language a.active {
		color: white;
		font-weight: bold;
	}
    
    .novik-list ul {
        left: 45vw;
        width: 40vw;
    }
}

/* 
    iOS
*/
@supports (-webkit-overflow-scrolling: touch) {
    header nav ul {        
        background: linear-gradient(17deg, rgba(2,0,36,0) 43%, $blue 43%);
    }
    header nav .language {
        bottom:9vh;
    }
    header input[type='checkbox']:checked ~ nav {		
        &::after {
            animation: dashing-fix .6s ease-in 1s 1 forwards;
        }
    }
    @keyframes dashing-fix {
        from {
            bottom: 92vh;
        }
        to {
            bottom: 26vh;
            opacity: 1;
        }
    }
}

/*
    Mobile
*/
@media (max-width: 767px) {
    html, body {
        font-size: 16px;
    }
    
    :root {
        --h1-font       : normal normal 200 1.8rem/3rem #{$font-stack};
        --h1-about-font : normal normal 200 3rem #{$font-stack};
        --h2-font       : normal normal 200 1.6rem/2rem #{$font-stack};
        --h2-bold-font  : normal normal 700 1.6rem/2rem #{$font-stack};
        --h3-font       : normal normal 200 2.2rem/3rem #{$font-stack};
        --h4-bold-font  : normal normal 700 1rem/1rem #{$font-stack};
    
        --button-font   : normal normal 700 1rem #{$font-stack};
        --tag-font      : normal normal 300 16px #{$font-stack};
    }

    #index section:nth-of-type(n),
    #case section:nth-of-type(n),
    #blog section:nth-of-type(n)
    {
        min-height: unset;
        padding: 10vw 7vw;
    }
    #case section:nth-of-type(2){
        padding: 30vw 7vw;
    }
    #case section:nth-of-type(4){
        padding: 10vw 7vw 10vw;
    }
    #contact section:nth-of-type(n) {
        min-height: unset;
        padding: 0 7vw;
    }
    
    #about section:nth-of-type(1) {
        min-height: 100vh;
    }
    
    #about section:nth-of-type(2) h2 {
        display: block;
        margin: 5vw 10vw 0;
        width: 60vw;
    }
    
    #about section:nth-of-type(2) p {
        display: block;
        width: 86vw;
    }
    
    #posts section span p {
        display: none;
    }
    header nav ul li a {
        font-size:2rem;
    }
    .content-block>ul:last-of-type li {
        color: black;
        text-shadow: none;
    }

    footer {
        text-align: center;
    }
    
    footer .footer-icon {
        float: none;
        display: block;
        margin: auto;
        margin-bottom:3rem;
        height: 25vw;
        width: 25vw;
    }
    
    footer .footer-icon::after {
        top: 100%;
        left: 50%;
        font: $p-font;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    
    footer nav {
        float: none;
    }
    
    footer nav ul li {
        display: block;
        line-height: 3rem;
    }
    
    .novik-list figure {
        display: none;
    }
    
    .novik-list ul {
        position: relative;
        left: 0;
        width: 82vw;
        padding: 2rem;
    }
    
    .novik-list ul li:nth-of-type(even) {
        background-size: auto 60px;
    }
    
    div.list {
        display: block;
        margin: 5vh 0;
        width: 75vw;
    }
}


@keyframes dashing {
    from {
        bottom: 92vh;
    }
    to {
        bottom: 30vh;
        opacity: 1;
    }
}
@keyframes dashing-white {
    0% {
        bottom:92vh;
    }
    50% {
        width: 0vw;
        bottom: 13vh;
        opacity: 1;
    }
    100% {
        width: 43vw;
        bottom: 13vh;
        opacity: 1;
    }
}