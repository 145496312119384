/*
 * COMPONENTS
 */

#posts section {
    
    .post-image {
        height: 270px;
        background-size: cover;
        background-position: center;
    }
    h2 {
        font-size:2.35rem;
    }
    .item {
        padding: 1.5rem 2.5rem;
    }
    .info {
        margin:1rem 0;
        font-weight:600;
        text-transform: uppercase;
        font-size: 1rem;
    
        & > div {
            display:inline;
        }
    }
    .btn {
        margin:1.5rem 0;
        a {
            font-size: 1.25rem;
        }
    }
}

#blog {
    h1 {
        font-size: 3.5rem;
        line-height: 4rem;
        font-weight:600;
    }
    .category {
        font-size: 1.175rem;
        margin:2rem 0;
    }
    h1, .category {
        text-transform: uppercase;
        display: block;
        text-align: center;
    }
    h2 {
        color:$blue;
        font-weight: 600;
        font-size:1rem;
        text-transform: uppercase;
    }
    p {
        font-size:1rem;
    }
    blockquote p {
        font-size:2rem;
    }
    .post-image img {
        width:100%;
        margin-bottom:1rem;
    }
    .text {
        margin:3rem 0;
    }
    .author-image {
        margin:1rem 0;
    }
    .back-button-top {
        margin-bottom:1rem;
    }
}


@media (max-width: 767px) {
    #blog {
        h1 {
            font-size: 1.8rem;
            line-height: 3rem;
            margin-bottom: 2rem;
        }
    }
}

.text-uppercase {
    text-transform: uppercase;
}
.text-small {
    font-size: .75rem;
    line-height: 1.5rem;
}
.nop-icon {
    margin-right: .75rem;
}

img.logo {
    float: left;
    height: calc(0.65 * #{$header-height});
}

span.mouse {
    display: block;
    margin: 4vh 1vw;
    height: 15vw; max-height: 80px;
    width: 20vw; max-width: 80px;
    background: transparent url('/img/mouse.png') no-repeat top center;
    background-size: contain;
}
span.mouse::after {
    display: block;
    position: absolute;
    left: 0; top: 120%;
    font-size: 0.8rem;
    content: attr(data-content);
    white-space: nowrap;
}

div.case-icon {
    display: inline-block;
    height: 15vw; min-height: 100px; max-height: 200px;
    width: 15vw; min-width: 100px; max-width: 200px;
    margin: 0 auto 0;
    overflow: hidden;
}

footer .footer-icon {
    margin: 10px 0;
    height: 70px;
    width: 70px;
    display: inline-block;
}

footer .footer-icon::after {
    position: absolute;
    font: $footer-font;
    font-size: .8rem;
    line-height: $header-height;
    content: attr(data-content);
    white-space: nowrap;
}

div.list {
    display: inline-block;
    margin: 0 2vw;
    width: 30vw;
    vertical-align: top;
}

div.list ul {
    margin: 0; padding: 0;
    width: 100%;
    list-style-type: none;
}

div.list ul li {
    padding: 1vw;
    margin: 1vw;
    border-bottom: 1px solid #dddde0;
}

ul.tags {
    position: relative;
    margin: 2vh auto;
    width: 80vw;
    list-style-type: none;
}

ul.tags li {
    display: inline-block;
    margin: 2vw 1vw 4vh 0;
    padding: 1vh 3vh;
    color: $grey;
    font: var(--tag-font);
    text-transform: uppercase;
    border: 1px solid $grey;
}

/*
 * Hamburger
 */
header label span {
	display: block;
	width: 33px;
	height: 4px;
	margin-bottom: 6px;
	
	background-color: $grey;
	border-radius: 3px;
	
	transform-origin: 0 2px;
	transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),opacity 0.55s ease, background-color 0.55s ease;
}

header #hamburger-menu:checked ~ label span {
	background-color: white;
	transform-origin: 0 2px;
}

/*
 * Hamburger transitions
 */
header #hamburger-menu:checked ~ label span:nth-of-type(1) {
	transform: rotate(45deg) translate(-2px, -1px);
}

header #hamburger-menu:checked ~ label span:nth-of-type(2) {
	opacity: 0;
}

header #hamburger-menu:checked ~ label span:nth-of-type(3) {
	transform: rotate(-45deg) translate(-1px, 0);
}

/*
 * Carousel
 */

#carousel {
    padding: 0;
    margin-bottom: 5vw;
    width: 80vw;
    white-space: nowrap;
    overflow: hidden;
}

#carousel input[type='radio'] {
    display: none;
}

#carousel>label {
    display: inline-block;
    position: relative;
    margin: 0; padding: 0;
    height: 100%; width: 80vw;
    vertical-align: top;
    white-space: normal;
    z-index: 1;
    transition: transform 600ms ease-in-out;
}

#carousel>label > * {
    margin: 0 5vw 2vw;
}

#carousel>label::before,
#carousel>label::after {
    display: block;
    position: absolute;
    top: 0;
    padding-top: 15vw;
    height: 100%; width: 30px;
    font: $h4-font;
    cursor: pointer;
}

#carousel>label::before {
    right: 100%;
    content: '\25b6';
}

#carousel>label::after {
    left: 100%;
    content: '\25c0';
}

#carousel>input:nth-of-type(1):checked ~ label {
    transform: translate3d(0, 0, 0);
}

#carousel>input:nth-of-type(2):checked ~ label {
    transform: translate3d(-100%, 0, 0);
}

#carousel>input:nth-of-type(3):checked ~ label {
    transform: translate3d(-200%, 0, 0);
}

#carousel>input:nth-of-type(1):checked ~ label:nth-of-type(1),
#carousel>input:nth-of-type(2):checked ~ label:nth-of-type(2),
#carousel>input:nth-of-type(3):checked ~ label:nth-of-type(3) {
    z-index: 0;
}

#carousel>div.btm-nav {
    display: block;
    position: absolute;
    bottom: 0;
    height: 20px; width: 80vw;
    text-align: center;
}
#carousel>div.btm-nav>label {
    position: relative;
    display: inline-block;
    margin: 0 4px;
    height: 5px;
    width: 5vw; max-width: 40px;
    cursor: pointer;
    background-color: rgb(100, 100, 100);
}

#carousel>input:nth-of-type(1):checked ~ div.btm-nav label:nth-of-type(1),
#carousel>input:nth-of-type(2):checked ~ div.btm-nav label:nth-of-type(2),
#carousel>input:nth-of-type(3):checked ~ div.btm-nav label:nth-of-type(3) {
    background-color: white;
}