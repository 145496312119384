/*
 * EXCEPTIONS
 */

 
@media (min-width: 768px) {
 .text-right {
     text-align: right;
 }
}

section:first-of-type::before {
    display: none;
}


div#vacancies-btn {
    position: absolute;
    display: block;
    top: -40px; left: 0;
    margin: 0;
    height: 40px;
    width: 100vw;
    transform: $rotation;
    transform-origin: bottom left;
}

div#vacancies-btn a {
    float: right;
    display: flex;
    top: 0; right: 10vw;
    height: 100%;
    width: 10vw; min-width: 150px;
    color: white;
    font: var(--tag-font);
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    transition: opacity .4s ease-out;
    background-color: $orange;
    border-radius: 10px 10px 0 0;
    z-index: 1;
}

div#vacancies-btn a:hover {
    opacity: .9;
} 

div#vacancies-btn a::after {
    display: inline-flex;
    position: absolute;
    align-items: center;
    top: -10px; right: -10px;
    height: 1.5vw; min-height: 20px;
    width: 1.5vw; min-width: 20px;
    color: $grey;
    font: var(--tag-font);
    content: attr(data-number);
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10vw;
}

#case section h2 strong, #about section h2 strong {
    color: $blue;
}

figure.ipad {
    display: inline-block;
    height: 60vw; width: 80vw;
    border-radius: 40px;
    background: white url('/img/cases/ipad.png') no-repeat;
    background-size: contain;
}

figure.ipad img {
    margin: 3vw;
    height: 90%; width: 90%;
}

.content-block .list ul>li {
    min-height: 10vh;
}

.content-block .list ul>li:last-of-type {
    border: none;
}

.content-block>ul:last-of-type li {
    margin: 0;
    text-align: left;
    color: white;
    font-size: 14px;
    text-shadow: 1px 1px 2px black;
}

.novik-list figure {
    width: 80vw;
}

.novik-list ul {
    position: absolute;
    top: 0; left: 50vw;
    margin: 2vw; padding: 2vw;
    width: 25vw;
    color: $grey;
    list-style-type: none;
    background-color: white;
    border-radius: 12px;
    box-shadow: $box-shadow;
    z-index: 2;
}

.novik-list ul li:nth-of-type(odd) {
    font: $header-font;
    text-transform: uppercase;
}

.novik-list ul li:nth-of-type(even){
    padding: 15px 80px 25px;
    color: $blue;
    font: var(--h2-font);
    font-weight: 700;
    white-space: nowrap;
}
.novik-list ul li:nth-of-type(even):not(:last-child) {
    margin:1rem 0;
    border-bottom: 2px solid black;
}

.novik-list ul li:nth-of-type(2) {
    background: url("/img/icons/grey/euro-munt.svg") no-repeat 0 0;
    background-size: auto 70px;
}
.novik-list ul li:nth-of-type(4) {
    background: url("/img/icons/grey/kado.svg") no-repeat 0 0;
    background-size: auto 70px;
}
.novik-list ul li:nth-of-type(6) {
    background: url("/img/icons/grey/euro-munt.svg") no-repeat 0 0;
    background-size: auto 70px;
}
.novik-list ul li:nth-of-type(8) {
    background: url("/img/icons/grey/like.svg") no-repeat 0 0;
    background-size: auto 70px;
}

footer li a::first-letter {
    text-transform: uppercase;
}

footer li a:hover {
    text-decoration: underline;
}

@supports (-ms-ime-align:auto) {
    #about section {        
        &:first-of-type {
            height: 50vw;
        }
        &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5) {
            margin-top:0 !important;
        }
        &:nth-of-type(2) {
            padding: 0 10vw !important;
        }
    }
}
@media all and (-ms-high-contrast:none) 
{
    #about section {        
        &:first-of-type {
            height: 50vw;
        }
        &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5) {
            margin-top:0 !important;
        }
        &:nth-of-type(2) {
            padding: 0 10vw !important;
        }
    }
}