/*
 * VARIABLES
 */

/*
 * COLORS
 */
    $grey: rgba(65, 65, 65, 1);
    $blue: rgba(0, 172, 255, 1);
    $orange: rgba(255, 137, 0, 1);
    $button: $blue;
    $button-hover: rgba(0, 155, 230, 1);
	
/*
 * FONTS
 */
    $font-stack    : 'Montserrat', 'Georgia', 'Times', 'Times New Roman', serif;
    
    $main-font     : normal normal 200 1vw $font-stack;
    
    $header-font   : normal normal 400 15px $font-stack;
    $footer-font   : normal normal 200 14px $font-stack;

    $h4-font       : normal normal 200 1.5rem $font-stack;    
    $p-font        : normal normal 200 1rem/2rem $font-stack;

    :root {
        
        --h1-font       : normal normal 200 4.8rem/4.8rem #{$font-stack};
        --h1-about-font : normal normal 200 6rem/6rem #{$font-stack};
        --h2-font       : normal normal 200 2.5rem/3rem #{$font-stack};
        --h2-bold-font  : normal normal 700 2rem #{$font-stack};
        --h3-font       : normal normal 200 2.75rem #{$font-stack};
        --h4-bold-font  : normal normal 700 1.5rem #{$font-stack};
        
        
        --button-font   : normal normal 700 1.4rem #{$font-stack};
        --tag-font      : normal normal 300 16px #{$font-stack};
    }

/*
 * LAY-OUT
 */
    $header-height: 70px;
    
    /* Width and height of the triangle */
    $skew-height: 32vw;
    $skew-width: 100vw;
    
    $rotation: rotate(-17.744deg); /* TAN 17.75deg = 32 / 100 */
    $jump-back: -15vw;
    
    /* ::before */
    $top-left: $skew-height 0 0 $skew-width;
    $top-right: $skew-height $skew-width 0 0;
    
    /* ::after */
    $bottom-left: 0 0 $skew-height $skew-width;
    $bottom-right: 0 $skew-width $skew-height 0;
    
    $button-height: 70px;
    $button-width: 200px;
    $box-shadow: rgba(69, 91, 99, 0.45) 0px 4px 16px 0px;


/*
 * GENERIC
 */

* {
    position: relative;
    margin: 0; padding: 0;
    box-sizing: border-box;
}

html, body {
    font: $main-font;
    font-size: 1.2vw;
    word-wrap: break-word;
    background-color: white;
}
body {
    overflow-x:hidden;
}