/*
 * CONTENT ELEMENTS - (nearly only) unclassed HTML
 */

h1, h2, h3, h4, p {
    display: block;
    color: inherit;
    text-align: inherit;
}

h1, h2, h3, h4 {
    text-transform: uppercase;
}

hr {
    margin: 0 10vw;
    width: 80vw;
    border: none;
    border-top: 1px solid $grey;
}

h1 {
    font: var(--h1-font);
}

h2 {
    font: var(--h2-font);
}

h2 strong, h2 b {
    font: var(--h2-bold-font);
    color: $blue;
}

h3 {
    padding-bottom: 2vw;
    font: var(--h3-font);
}

h4 {
    font: $h4-font;
}

h4 strong, h4 b {
    font: var(--h4-bold-font);
    color: $blue;
}

h4 em {
    font: var(--h4-bold-font);
    color: white;
    display: block;
}

blockquote, blockquote p {
    font: var(--h1-font);
    color:$blue;
    font-weight: 600;
    text-align: center;
}
main {
    display:block;
}

p {
    padding-bottom: 3vh;
    font: $p-font;
}

a {
    color: inherit;
    text-align: inherit;
    text-decoration: none;
}
strong {
    // font-weight: bolder;
}

table, th, tr, td {
    border: 2px solid black;
    border-collapse: collapse;
}

th, td {
    padding: 1vw;
}

form {
    margin-top: 5vh;
}

textarea, select, input, button, div.btn {
    outline: none;
    cursor: pointer;
}

form textarea, form select, form input {
    min-height: $button-height;
    width: 100%;
	margin: 1vw 0;
	padding: 1rem 1.5rem;
	font: $p-font;
	border: 1px solid rgba(69,91,99,0.25);
	border-radius: 0.75rem;
    box-shadow: 0 4px 16px 0 rgba(69,91,99,.25);
    -moz-box-shadow: 0 4px 16px 0 rgba(69,91,99,.25);
    -webkit-box-shadow: 0 4px 16px 0 rgba(69,91,99,.25);
    transition: all 300ms linear; 
}

form textarea {
    height: 150px;
    resize: vertical;
}

button, div.btn>a {
    display: inline-flex;
    margin: 0 auto;
    padding: 1rem 2rem;
    color: white;
    font: var(--button-font);
    text-decoration: none !important;
    text-transform: uppercase;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
    background-color: $button;
    overflow: hidden;
    z-index: 1;
}

button:hover, div.btn>a:hover {
    background-color: $button-hover;
}

form button:last-of-type, form div.btn:last-of-type {
    margin-top: 2vw;
}

form { 
    
    label {
        top: 10px;
        font: var(--h2-font);
    }
    textarea:focus:invalid, input:focus:invalid {
        outline: none;        
    }
    .captcha {
        font-size:2.5rem;
    }
    
    .invalid {
        border:1px solid #e6917a;
    }
        
    .error {
        display: none;
        margin-bottom: 0.5rem;
        margin-top: calc(-0.5rem - 4px);
        margin-left: 2px;
        margin-right: 2px;
        padding: calc(0.5rem + 4px) 0.6rem;
        color: #b75a41;
        animation: show-error 0.3s;
        font-size: 0.8em;
    }
        
    .invalid + .error {
        display: block;
    }
    
}

ul, ol {
    padding: 0 0 2vw 5vw;
}

ul li, ol li {
    margin: 1vw 0;
    font: $p-font;
}

section figure {
    margin: 5vw auto 0;
    padding: 3vw;
    height: auto;
    width: 80vw;
    background-color: white;
    border-radius: 12px;
    box-shadow: $box-shadow;
    z-index: 1;
}

section .content-block {
    margin: 5vw auto 0;
    padding: 1vw;
    min-height: 40vh;
    width: 80vw;
    text-align: center;
    z-index: 1;
}

section .content-block .list {
    background-color: white;
    border-radius: 12px;
    box-shadow: $box-shadow;
}

section figure.photograph {
	padding: 0;
	background-color: transparent;
	box-shadow: none;
}

section figure img {
    height: 95%; 
    width: 95%;
}

section .video {
    display: inline-block;
    margin: 5vh 0 7vh;
    height: 45vw; width: 80vw;
    text-align: left;
    overflow: hidden;
    z-index: 1;
}

section .video iframe {
    height: 100%; width: 100%;
    border: none;
}

.text-blue {
    color: $blue;
}


@media all and (-ms-high-contrast:none)
{

    h1 {
        font: normal normal 200 4.8rem/4.8rem #{$font-stack};
    }
    
    h2 {
        font: normal normal 200 2.5rem/3rem #{$font-stack};
    }
    
    h2 strong, h2 b {
        font: normal normal 700 2.5rem/3rem #{$font-stack};
    }
    
    h3 {
        font: normal normal 200 2.75rem #{$font-stack};
    }
    
    h4 strong, h4 b {
        font: normal normal 700 1.5rem #{$font-stack};
    }
    
    h4 em {
        font: normal normal 700 1.5rem #{$font-stack};
    }
    
    button, div.btn>a {
        font: normal normal 700 1.4rem #{$font-stack};
    }
    form {     
        label {
            font: normal normal 200 2.5rem/3rem #{$font-stack};
        }
    }
}

@keyframes show-error {
    from {
        opacity: 0;
        transform: translateY(-2em) scaleY(0.2);
    }

    to {
        opacity: 1;
        transform: none;
    }
}